/*Comanda*/
.comanda{
  .container {
    background: linear-gradient(to left, #024824, #00954b 25%, #00954b 75%, #024824 100%);
    width: 100%;
    padding: 0;
    position: relative;
    .details{
      max-width: 1170px;
      margin:0 auto;
      background: transparent;
      .text-center{
        h3{
          font-size: 48px;
          color: #fff;
        }
        p{
          font-size:18px;
          color: #fff;
          text-align: center;
          margin-bottom: 50px;
        }
      }
      select {
          /* styling */
          background-color: white!important;
          border: thin solid blue!important;
          border-radius: 4px!important;
          display: inline-block!important;
          font: inherit!important;
          line-height: 1.5em!important;
          padding: 0.5em 3.5em 0.5em 1em!important;

          /* reset */
          margin: 0!important;
          -webkit-box-sizing: border-box!important;
          -moz-box-sizing: border-box!important;
          box-sizing: border-box!important;
          -webkit-appearance: none!important;
          -moz-appearance: none!important;
          border: 0 !important;
        }
      .tabel{
        width: 30%;
        margin-left: 35%;
        margin-bottom: 10%;
        padding-top: 25px;
        background: linear-gradient(to left, #00a651, #00a651 25%, #00a651 75%, #00a651 100%);
        .form-group{
          margin-bottom: 22px;
          .form-control{
            display: block;
            width: 100%;
            height: 34px;
            padding: 6px 25px;
            font-size: 16px;
            line-height: 1.42857143;
            color: #9a9a9a;
            background-color: #f4f4f4;
            border: 1px solid #ccc;
            border-radius: 0;
          }
          p{
            font-size:18px;
            color: #fff;
            text-align: center;
            margin-bottom: 10px;
          }
          select.minimal {
              background-image:
                      linear-gradient(45deg, transparent 50%, gray 50%),
                      linear-gradient(135deg, gray 50%, transparent 50%),
                      linear-gradient(to right, #ccc, #ccc);
              background-position:
                      calc(100% - 20px) calc(1em + 2px),
                      calc(100% - 15px) calc(1em + 2px),
                      calc(100% - 2.5em) 0.5em;
              background-size:
                      5px 5px,
                      5px 5px,
                      1px 1.5em;
              background-repeat: no-repeat;
            }

          select.minimal:focus {
            background-image:
                    linear-gradient(45deg, green 50%, transparent 50%),
                    linear-gradient(135deg, transparent 50%, green 50%),
                    linear-gradient(to right, #ccc, #ccc);
            background-position:
                    calc(100% - 15px) 1em,
                    calc(100% - 20px) 1em,
                    calc(100% - 2.5em) 0.5em;
            background-size:
                    5px 5px,
                    5px 5px,
                    1px 1.5em;
            background-repeat: no-repeat;
            border-color: green;
            outline: 0;
          }

          .read-more{
            padding: 10px 35px;
            background: #ffcb08;
            font-size: 18px;
            color: #fff;
            margin-left: 85px;
            margin-top: -15px;
            border:none;
          }
        }
      }



    }
  }

}
/*END Comanda*/
