@import '_fonts';
@import url('//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-theme.min.css');
@import url('//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css');

/* Colors */
$lightblack:#221e1f;
$red:#d71921;
$lightgreen: #9ccb48;
$lightgrey: #908f8f;
$font-regular:'Prompt', sans-serif;
$font-demi:'Avenir Next LT Pro Demi', Arial, sans-serif;
body{
  font-family: $font-regular;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

#myCarousel{
  .carousel-control{
    background-image: none;
    filter: none;
  }
  position: relative;
  .item{
    //position: relative;
    .mainImg{
      position: absolute;
      top:0;
      right: 0;
      z-index:10;
    }
    .bannerImage{
      position: absolute;
      top:0;
      left: 12%;
      z-index: 20000;
    }
    .description{
      font-family:$font-regular;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 23%;
      h1{
        font-size: 48px;
        font-weight: 600;
        text-transform: uppercase;
      }
      p{
        font-size: 30px;
        font-weight: 200;
      }
    }
  }
  .links{
    a:first-child{
      position: absolute;
      top: 40%;
      right: 40%;
      z-index:20000000;
    }
    a:last-child{
      position: absolute;
      top: 60%;
      right: 30%;
      z-index:20000000;
    }
  }
}
.carousel-fade .carousel-inner .item {
  opacity: 0.4;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0.4;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-indicators {
  bottom: 0;
}

#myCarousel2{
  margin-top: 100px;
}
//
//.vertical .carousel-inner {
//  height: 100%;
//}
//
//.carousel.vertical .item {
//  -webkit-transition: 0.6s ease-in-out top;
//  -moz-transition: 0.6s ease-in-out top;
//  -ms-transition: 0.6s ease-in-out top;
//  -o-transition: 0.6s ease-in-out top;
//  transition: 0.6s ease-in-out top;
//}
//
//.carousel.vertical .active {
//  top: 0;
//}
//
//.carousel.vertical .next {
//  top: 800px;
//}
//
//.carousel.vertical .prev {
//  top: -800px;
//}
//
//.carousel.vertical .next.left,
//.carousel.vertical .prev.right {
//  top: 0;
//}
//
//.carousel.vertical .active.left {
//  top: -800px;
//}
//
//.carousel.vertical .active.right {
//  top: 800px;
//}
//
//.carousel.vertical .item {
//  left: 0;
//}

@import '_homepage';
@import '_about';
@import '_articles';
//@import '_produse';
@import '_contact';
@import '_comanda';

@import '_responsive';
