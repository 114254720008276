/*Contact*/
.contact-body{
  .container {
    background: linear-gradient(to left, #024824, #00954b 25%, #00954b 75%, #024824 100%);
    width: 100%;
    padding: 0;
    position: relative;
    .details{
      max-width: 1170px;
      margin:0 auto;
      background: transparent;
      .text-center{
        h3{
          font-size: 48px;
          text-align: center;
          color: #fff;
          margin-top: 10px;
        }
        p{
          font-size:24px;
          color: #fff;
          text-align: left;
        }
      }
      .form-group{
        margin-bottom: 22px;
        .form-control{
          display: block;
          width: 100%;
          height: 34px;
          padding: 6px 25px;
          font-size: 16px;
          line-height: 1.42857143;
          color: #9a9a9a;
          background-color: #f4f4f4;
          border: 1px solid #ccc;
          border-radius: 0;
        }
        .read-more{
          padding: 15px 2rem 15px 2rem;
          background: #ffcb08;
          font-size: 18px;
          color: #fff;
          margin-top: 20px;
          margin-left: -1px;
          border:none;
        }
        textarea{
          background-color: #f4f4f4;
          color: #9a9a9a;
          padding: 2rem 0 0 1rem;
        }
      }
      hr{
        margin: 6rem -2rem 21px 0rem;
        border: 0;
        border-top: 1px solid #0ab15e;
      }
    }

    .contact{

      /*padding-bottom: 4rem;*/
      max-width: 1170px;
      /*padding-right: 13rem;*/
      .adress{
        h2{
          font-size: 18px;
          color: #fff;
        }
        p{
          font-size:16px;
          color: #fff;
          line-height: 28px;
        }
      }
      .phone{
        float: left;
        padding-left: 6%;
        border-right: 1px solid #0ab15e;
        border-left:1px solid #0ab15e;
        margin-top: 25px;
        margin-left: -5%;
        h2{
          font-size:18px;
          color: #fff;
          font-weight: initial;
          margin: 3px 0;
        }
        p, a{
          font-size:16px;
          color: #fff;
          font-weight: 300;
          line-height: 28px;
        }
      }
      .social{
        padding-left: 4%;
        h2{
          font-size: 18px;
          color: #fff;
        }
        p, a, a:hover, a:focus{
          font-size:16px;
          color: #fff;
          font-weight: 300;
          line-height: 28px;
        }
      }
    }
  }
}
footer{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 2em 0;
  position: relative;
  .footer {
    max-width: 1170px;
    background-color: #fff;
    margin: 0 auto;
    height:auto;
    ul{
      list-style-type: none;
      font-size: 14px;
      color: #d71921;
      margin-left: -14%;
      li{
        color: #d71921;
        float: left;
        margin-left: 5rem;
        font-size: 14px;
        a, a:focus, a:hover{
          color: #d71921;
          margin-left: 5rem;
        }
      }
    }
    p{
      margin-left: 7rem;
      font-size: 14px;
      line-height: 16px;
      color: #d71921;
    }
    .img-responsive{
      float: right;
      margin: -7rem 3rem 0 0;
    }
    .bar{
      border-left:1px solid #d71921;
      padding-left: 5rem;
    }

  }
  .kangur{
    background: url("../../../img/kangur.png") no-repeat;
    width: 280px;
    height: 380px;
    bottom: 0;
    right: 0;
    position: absolute;
    border: 0;
    z-index: 2000;
  }
}
/*END Contact*/
