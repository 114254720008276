@media only screen and (min-width : 300px) and (max-width : 500px) {

}
@media only screen and (min-width : 500px) and (max-width:767px){



}
@media(max-width:800px){

}
@media only screen and (min-width : 768px) and (max-width : 1200px) {
  .navbar-header{
    img{
      //width: 60%;
    }
  }
  #navbar{
    ul{
      li{
        a{
          font-size:0.8em;
          padding: 40px 20px 0 20px;
          text-transform: uppercase;
        }
      }
    }
  }
}
@media(min-width:992px){

}
/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) and (max-width : 1499px) {
  .navbar, .navbar:hover {
    height:115px;
  }
}
/* Large Devices, Wide Screens */
@media only screen and (min-width : 1500px) {

}

@media screen and (max-height: 450px) {

}

/*Home*/

@media only screen and (max-width : 1280px) and (min-width : 800px) {
  .container{
    #myCarousel{
      .carousel-inner{
        background-size: contain!important;
      }
      .item {
        height: 575px;
        .bannerImage {
          left: 13%;
          width: 40%;
        }
        .mainImg {
          width: 46%;
        }
        .description{
          h1{
            margin: 4% 0 0 -8%;
          }
          p{
            font-size: 25px;
            margin-left: -8%;
          }
        }
      }
      .links{
        a:first-child{
          right: 37%;
          top:25%;
        }
        img{
          width: 45%;
          margin-top: 8rem;
        }
        a:last-child{
          top: 50%;
          right: 26%;
        }
      }
    }
    .details{
      .details-panel{
        .row{
          .text-centrat{
            margin-top: 0!important;
          }
          .text-right{
            img{
              margin-top: -13%;
            }
          }
        }
        .view{
          .btn-default{
            margin-top: 2rem!important;
          }
        }
      }
    }
    .about{
      p{
        margin-left: 2rem;
        font-size: 18px;
        span{
          font-size:18px;
        }
        img{
          margin-top: -1rem!important;
          margin-right: 0!important;
        }
      }
    .btn-default{
      float: right;
      margin: -10% -25% 0 0!important;
    }
    .about-footer{
      .btn-default{
        margin: 0!important;
        float: none;
      }
    }
  }
}
  footer{
    .footer{
      ul{
        margin-left: 5px;
        font-size: 18px !important;
        float: left!important;
      }
      .col-lg-4{
        padding-top: 3px!important;
        margin-top: -25px!important;
        p{
          margin-left: 5rem;
        }
        .img-responsive{
          margin-right: 30px!important;
        }
      }
    }
  }
  .page{
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
          }
          .text-centrat{
              margin-top: 0!important;
            }
          .text-right{
            margin-top: 0!important;
            img{
              margin-top: 0!important;
            }
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          margin-left: 50px;
          padding-left: 40px;
          ul{
            li{
              font-size:18px!important;
              padding: 0;
            }
          }
        }
        .col-lg-4{

        }
      }
    }
  }
  .articole{
    .container{
      .row{
        text-align: center;
        .date{
          margin-right: 27%;
        }
        h1{
          font-size: 18px;
          color: #fff;
          margin-right: 7rem;
        }
        h2{
          margin-left: 21%;
        }
      }
    }
    .articol{
      hr{

      }
    }
    footer{
      .footer{
        ul{
          margin: 0 auto;
          font-size: 14px;
        }
        .col-lg-4{
          p{
            font-size: 14px!important;
            margin-top: 7px!important;
          }
          .img-responsive{
            margin-top: -40%;
          }
        }
      }
    }
  }
  .comanda{
    footer{
      .footer{
        ul{
          margin: 1rem 0 0 3rem!important;
        }
        .col-lg-4{
          p{
            margin: -2rem 0 0 -3rem!important;
          }
          .img-responsive{
            margin-top: -75%;
          }
        }
      }
    }
  }

}
@media only screen and (min-width : 800px) and (max-width : 1280px) {
  .container-fluid{
    .navbar-brand{
      img{
        display: block;
        width: 60%;
        margin: 0;
        padding-top:15px;
      }
    }
    .navbar-header{
      .navbar{
        .navbar-nav{
          height: 100%;
          margin-top: -5rem;
          margin-left: 17rem!important;
        }
      }
    }
  }
  .homepage{
    .container{
      .slider{
        margin: 0;
      }
      #myCarousel{
        .carousel-inner{
          background-size: contain!important;
        }
        .item {
          height: 365px;
          .bannerImage {
            left: 13%!important;
            width: 40%!important;
          }
          .mainImg {
            width: 46%;
          }
          .description{
            top: 29%;
            left: 18%;
            h1{
              margin: 22% 0 0 -8%;
              font-size:30px;
            }
            p{
              font-size: 20px;
              margin-left: -8%;
            }
          }
        }
        .links{
          a:first-child{
            right: 33%!important;
            top:1%!important;
          }
          img{
            width: 45%;
            margin-top: 8rem;
          }
          a:last-child{
            top: 45%;
            right: 15%;
          }
        }
      }
      .news{
        .news-panel{
          .text-center{
            h2{
              margin-top: 25px!important;
            }
          }
          .pull-right{
            padding-left: 0!important;

          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              img{
                margin-top: 0!important;
              }
            }
          }
        }
      }
      .about{
        width: 100%;
        margin: 0 auto;
        .col-lg-7{
          margin-left: 2rem!important;
          p {
            padding-right: 45%;
            font-size: 18px;
          }
          img{
            margin: 0!important;
            float: left;
          }
        }
        .col-lg-5{
          padding-right: 25%;
        }
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 10px;
        li{
          font-size: 10px;
          margin: 0 auto!important;
          a{
            margin-left: 2px!important;
          }
        }
      }
      .col-lg-5{
        margin: -3rem 0 0 50rem;
        p{
          font-size: 12px;
          text-align: left!important;
        }
      }
    }
  }
  .page{
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right:none;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right:none;
          }
          .text-right{
            margin-top: 0!important;
            border-right:none;
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          margin: 0;
          padding: 0;
          ul{

          }
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        text-align: center;
        .date{
          margin-right: 27%;
        }
        h1{
          font-size: 18px;
          color: #fff;
          margin-right: 7rem;
        }
        h2{
          margin-left: 21%;
        }
      }
      .row{
        .text-center{
          margin-left: 4rem;
        }
      }
    }
    .row{
      .articol{
        img{
          width: auto;
          margin-top: 3rem;
        }
      }
    }
    footer{
      .footer{
        .col-lg-4{
          padding-right: 12%!important;
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 35px!important;
            }
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          width: 50%;
        }
      }
    }
  }
}
@media only screen and (max-width : 1024px) and (min-width : 768px) {
  .homepage{
    .container{
    #myCarousel{
      .carousel-inner{
        background-size: cover!important;
      }
      .item {
        height: 365px;
        .bannerImage {
          left: 17%;
          width: 35%;
        }
        .mainImg {
          width: 46%;
        }
        .description{
          top: 29%;
          left: 18%;
          h1{
            margin: 22% 0 0 -8%;
            font-size:30px;
          }
          p{
            font-size: 20px;
            margin-left: -8%;
          }
        }
      }
      .links{
        a:first-child{
          right: 37%!important;
          top:14%;
        }
        img{
          width: 45%;
          margin-top: 8rem;
        }
        a:last-child{
          top: 45%;
          right: 26%;
        }
      }
    }
    .news{
      .news-panel{
        .pull-right{
          margin: -37rem 7rem 0 0;
        }
      }
    }
    .details{
      .details-panel{
        .row{
          .text-centrat{
            margin: 0 auto;
          }
          .text-right{
            margin: 0 auto;
          }
        }
        .view{
          .btn-default{
            margin-top: -15rem;
          }
        }
      }
    }
    .about{
      p{
        font-size: 18px;
        margin: 0;
      }
      .col-lg-5{
        float: right;
        padding-right: 16%;
        margin-top: -3rem;
      }
      .btn-default{
        margin-left: -13rem;
      }
    }
  }
}
  footer{
    .footer{
      ul{
        padding-left: 20%!important;
      }
      .col-lg-5{
        padding-top: 8px!important;
        p{
          text-align: left!important;
        }
        .img-responsive{
          margin: -6rem 26rem 0 0;
        }
      }
    }
  }
  .page{
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right: 1px solid #fff;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right: 1px solid #fff;
          }
          .text-right{
            margin-top: 0!important;
            border-right:none;
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-4{
          margin-top: 0;
        }
        .img-responsive{
          padding-left: 45px;
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .col-md-4{
          width: 40%;
          margin-left: 7rem;
        }
        .date{
          margin: 0 2rem 2rem 0;
        }
        h2{
          margin-left: 1rem;
        }
      }
    }
    footer{
      .footer{
        .col-lg-4{
          .img-responsive{
            margin-top: -28%;
            padding-left: 7%;
          }
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 63px!important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1024px) and (orientation: portrait) {

  .navbar{
    width: 100%;
    .navbar-brand{
      img{
        max-width: 60%;
        margin-top: 15px;
      }
    }
    #navbar{
      ul{
        li{
          a{
            padding: 40px 10px 0 10px!important;
          }
        }
      }
    }
  }
  .homepage{
    .container{
      .slider{
        margin-top: 10rem;
      }
      #myCarousel{
        .carousel-inner{
          background-size: contain!important;
        }
        .item {
          height: 365px;
          .bannerImage {
            left: 17%;
            width: 35%;
          }
          .mainImg {
            width: 46%;
          }
          .description{
            top: 29%;
            left: 18%;
            h1{
              margin: 13% 0 0 -8%;
              font-size:30px;
            }
            p{
              font-size: 20px;
              margin-left: -8%;
            }
          }
        }
        .links{
          a:first-child{
            right: 31%;
            top:8%;
          }
          img{
            width: 45%;
            margin-top: 8rem;
          }
          a:last-child{
            top: 38%;
            right:20%;
          }
        }
      }
      .news{
        .news-panel{
          .text-center{
            h2{
              margin-top: 25px;
            }
          }
          .pull-right{
            margin: 2rem -14rem 0 0;
            img{
              margin-left: 0!important;
            }
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: 2%;
            }
          }
        }
      }
      .about{
        .col-lg-7{
          margin-left: 0!important;
        }
        p{
          margin-left: 2rem;
          font-size: 18px;
          img{
            margin: 0;
          }
        }
        .btn-default{
          margin: 3rem 0 -2rem 15rem;
        }
      }
    }
  }
  footer{
    .footer{
      ul{
        margin-left: -12rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-10{
        margin-top: 25px;
        text-align: center;
        p{
          float: left;
          padding-left: 40%;
        }
        .img-responsive{
          padding: 45px 0 0 25px;
        }
      }
    }
  }
  .page{
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right:none;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right:none;
          }
          .text-right{
            margin-top: 0!important;
            border-right:none;
            padding-top: 20px;
          }
        }
      }
    }
    footer{
      .footer{
        ul{
          li{
            margin-left: 30px!important;
            margin-right: 2px!important;
            a{
              margin-left: 0;
            }
          }
        }
        .col-lg-4{
          p{
            font-size:12px;
          }
          .img-responsive{
            padding-left: 0;
          }
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .col-md-4{
          width: auto;
          margin-left: 10rem;
          padding-left: 10rem;
        }
        .date{
          margin-right: 27%;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 15%;
        }
      }
      .row{
        .text-center{
          margin-left: 5rem;
        }
      }
    }
    .articol{
      img{
        margin: 13rem 3em 0 10rem;
        float: none;
      }
      h1{
        margin-top: 2rem;
      }

    }
    footer{
      .footer{
        .col-lg-8{
          ul{
            li{
              margin-left: 5px;
              float: left;
              padding-right: 30px;
              .bar{
                padding-left: 0;
              }
            }
          }
        }
        .col-lg-4{
          font-size:14px;
          margin-top: 0;
          P{
            font-size:14px;
          }
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .text-center{
          h3{
            margin-top: 15%!important;
          }
        }
        .tabel{
          .form-group{
            .read-more{
              margin-left: 30px!important;
            }
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          width: 100%;
        }
        .col-lg-4{
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (min-width : 414px) and (max-width : 736px) {
  .navbar{
    width:100%;
    #navbar{
      padding:0;
      overflow: inherit;
    }
    .navbar-nav{
      margin: 0 !important;
    }
    .navbar-toggle .icon-bar {
      background-color: #fff;
    }
    .navbar-toggle{
      margin-top: 30px;
      border-color: #fff;
    }
    .navbar-brand{
      max-width:80%;
      margin-top: 10px;
    }
    .navbar-nav, .navbar:hover .navbar-nav{
      width: 100%;
      padding: 0;
      margin: 0;
      li{
        width:100%;
        height:auto!important;
        a{
          width: 100%;
          text-align: center;
          padding: 20px 0!important;
        }
        ul{
          li{
            a{
              color: #fff!important;
            }
          }
        }
      }
    }
  }
  .homepage{
    .container{
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 11rem;
            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: -13rem;
            }
          }
        }
      }
      .about{
        p{
          margin-left: -1rem;
          font-size: 14px;
          img{
            margin: 0;
          }
        }
        .btn-default{
          margin: 3rem 0 -2rem 3rem;
          font-size: 15px;
        }
      }
    }
    footer{
      .footer{
        ul{
          margin-left: 0!important;
          li{
            font-size: 12px;
            margin-left: 20px;
          }
        }
        p{
          font-size: 14px;
          margin: 4rem 0 0 2rem;
        }
        .img-responsive{
          margin: -6rem 13rem 0 0;
        }
        .col-lg-5{
          margin-top: 15px!important;
        }
        .kangur{
          width: 139px;
          height: 290px;
        }
      }
    }
  }
  .page{
    .page-description{
      h1{
        margin-top: 5rem;
        padding-top: 7rem;
      }
    }
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right:none;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right:none;
            p{
              margin-bottom: 5rem;
            }
          }
          .text-right{
            margin-top: 0!important;
            border-right:none;
          }
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .col-md-4{
          width: auto;
          padding-left: 2rem;
        }
        .date{
          margin-right: 6%;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
        }
      }
      .row{
        .text-center{
          margin: 0;
          padding-left: 27%;
        }
      }

    }
    .articol{
      img{
        width: auto;
        height: auto;
        margin-bottom: 3rem;
      }
      h1{
        font-size: 23px!important;
        margin: 0;
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          width: 60%!important;
          margin-left: 21%!important;
          .form-group{
            .read-more{
              margin-left: 30px!important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 375px) and (max-width : 667px) {
  .homepage{
    .container{
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 15rem;
            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: -13rem;
            }
          }
        }
      }
      .about{
        p{
          margin-left: -1rem;
          font-size: 13px;
          img{
            margin: 0!important;
          }
        }
        .btn-default{
          margin: 3rem 0 -2rem 3rem;
          font-size: 15px;
        }
      }
    }
    footer{
      .footer{
        ul{
          margin-left: -13rem;
          li{
            font-size: 7px;
            padding: 0 1rem;
            margin: 0 -8rem 0 8rem;
          }
        }
        p{
          font-size: 12px;
          width: 50%!important;
          padding: 20px 25px 0 0;
          margin-left: 20%!important;
        }
        .img-responsive{
          margin-top: 0;
          padding-left: 0!important;
        }
        .kangur{
          width: 139px;
          height: 290px;
          display: none;
        }
      }
    }
  }
  .page{
    .page-description{
      h1{
        margin-top: 5rem;
        padding-top: 7rem;
      }
    }
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right:none;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right:none;
            p{
              margin-bottom: 5rem;
            }
          }
          .text-right{
            margin-top: 0!important;
            border-right:none;
          }
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .col-md-4 img{
          width: 100%;
        }
        .date{
          margin: 0 2rem 2rem 0;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
          font-size: 18px;
        }
      }
      .row{
        .text-center{
          margin: 0;
          padding-left: 27%;
        }
      }

    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          width: 60%!important;
          margin-left: 21%!important;
          .form-group{
            .read-more{
              margin-left: 30px!important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 320px) and (max-width : 568px) {

  .navbar{
    #navbar{
      padding:0;
      overflow: inherit;
      margin-top: 20px;
    }
    .navbar-nav{
      margin: 0 !important;
    }
    .navbar-toggle .icon-bar {
      background-color: #fff;
    }
    .navbar-toggle{
      margin-top: 30px;
      border-color: #fff;
    }
    .navbar-brand{
      max-width:80%;
      margin-top: 10px;
    }
    .navbar-nav, .navbar:hover .navbar-nav{
      width: 100%;
      padding: 0;
      margin: 0;
      li{
        width:100%;
        height:auto!important;
        a{
          width: 100%;
          text-align: center;
          padding: 20px 0!important;
        }
        ul{
          li{
            a{
              color: #fff!important;
            }
          }
        }
      }
    }
  }
  .homepage{
    .container{
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 15rem;

            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: -13rem;
            }
          }
        }
      }
      .about{
        p{
          margin-left: 5rem;
          font-size: 16px;
          img{
            margin: 1rem 0 0 -1rem;
          }
        }
        .btn-default{
          margin: 3rem 0 -2rem 3rem;
          font-size: 15px;
        }
      }
      .contact{
        padding-right: 0;
        .adress{
          margin: 0!important;
          width: 100%!important;
        }
        .phone{
          margin: 0;
          width: 100%;
        }
        .social{
          margin: 0;
          width: 100%;
        }
      }
    }
    footer{
      .footer{
        ul{
          //margin-left: -9rem;
          margin:0;
          padding:0;
          li{
            //font-size: 14px;
            margin: 0;
            padding: 0 5px;
            font-size:11px;
            a, a:hover{
              margin:0;
            }
          }
        }
        p{
          font-size: 12px;
          margin-left: 55px;
        }
        .img-responsive{
          float: right;
        }
        .kangur{
          width: 139px;
          height: 290px;
          display: none;
        }
      }
    }
  }
  .page{
    .page-description{
      h1{
        margin-top: 5rem;
        padding-top: 7rem;
        font-size: 18px;
      }
      h2{
        font-size: 16px;
      }
    }
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right:none;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right:none;
            p{
              margin-bottom: 5rem;
            }
          }
          .text-right{
            margin-top: 0!important;
            border-right:none;
          }
        }
      }
    }
    footer{
      .footer{
        .img-responsive{
          float: right!important;
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .col-md-4 img{
          width: 100%;
        }
        .date{
          margin: 0 2rem 6rem 0;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
        }
      }
      .row{
        .text-center{
          margin: 0;
          padding-left: 22%;
        }
      }

    }
    .articol{
      img{
        width: 100%;
        height: auto;
        margin-bottom: 3rem;
      }
      h1{
        font-size: 23px!important;
        margin: 0;
      }
    }
    footer{
      .footer{
        .img-responsive{
          float: right;
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          width: 60%!important;
          margin-left: 21%!important;
          .form-group{
            .read-more{
              margin-left: 10px!important;
            }
          }
        }
      }
    }
    footer{
      footer{

      }
    }
  }
}
@media only screen and (min-width : 384px) and (max-width : 640px) {
  .homepage{
    .container{
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 15rem;

            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: -13rem;
            }
          }
        }
      }
      .about{
        p{
          margin-left: -1rem;
          font-size: 13px;
          img{
            margin: -3rem 0 0 15rem;
          }
        }
        .btn-default{
          margin: 3rem 0 -2rem 3rem;
          font-size: 15px;
        }
      }
    }
    footer{
      .footer{
        .col-lg-12{
          text-align: center;
          //margin-left: 7rem;
          ul{
            margin-left:0;
            li{
              font-size: 10px;
              margin-left: 20px;
            }
          }
        }
        .col-lg-10{
          p{
            font-size: 12px;
            margin: 4rem 0 0 1rem;
          }
          .img-responsive{
            margin-top: -18%;
          }
        }
        .kangur{
          width: 139px;
          height: 290px;
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .date{
          margin: 0 2rem 2rem 0;
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 27px!important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 360px) and (max-width : 640px) {
  .homepage{
    .container{
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 5rem;

            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
              border: none
            }
            .text-right{
              margin-top: 6rem;
              border: none
            }
            .text-left{
              border: none;
            }
          }
          .view{
            .btn-default{
              margin-top: -13rem;
            }
          }
        }
      }
      .about{
        p{
          margin-left: 3rem;
          font-size: 14px;
          img{
            margin: 0;
          }
          span{
            font-size:14px;
          }
        }
        .btn-default{
          margin: 3rem 0 -2rem 5rem;
          font-size: 15px;
        }
      }
    }
    footer{
      .footer{
        ul{
          //margin-left: -14rem;
          margin: 0;
          padding:0;
          width:100%;
          li{
            font-size: 12px;
            //margin: 0 -9rem 0 9rem;

          }
        }
        p{
          font-size: 12px;
          margin-top: 10px!important;
        }
        .img-responsive{
          /*margin-top: 10px;*/
          /*padding-right: 45px;*/
        }
        .kangur{
          width: 139px;
          height: 290px;
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        .date{
          margin: 0 2rem 2rem 0;
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 20px!important;
            }
          }
        }
      }
    }
  }

}
@media only screen and (min-width : 412px) and (max-width : 732px) {
  .homepage{
    .container{
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 15rem;

            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: -13rem;
            }
          }
        }
      }
      .about{
        .btn-default{
          margin: 3rem 0 -2rem 6rem;
          font-size: 15px;
        }
        p{
          margin-left: -1rem;
          font-size: 13px;
          img{
            margin: 0;
          }
        }

      }
    }
    footer{
      .footer{
        ul{

          li{
            font-size: 12px;
            margin-left: 20px;
          }
        }
        p{
          font-size: 12px;
          margin: 4rem 0 0 8rem;
        }
        .img-responsive{
          margin: -6rem 2rem 0 0;
        }
        .kangur{
          width: 139px;
          height: 290px;
          display: none;
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 35px!important;
            }
          }
        }
      }
    }
  }

}
@media only screen and (max-width : 960px) and (min-width : 600px) {
  .homepage{
    .container{
      .slider{
        #myCarousel{
          .carousel-inner{
            background-size: cover!important;
          }
          .item {
            //height: 326px;
            .bannerImage {
              left: 22%!important;
              width: 30%!important;

            }
            .mainImg {
              width: 46%;
            }
            .description{
              top:4%;
              h1{
                margin: 49% 0 0 -20%;
                font-size: 30px;
              }
              p{
                font-size: 18px;
                margin-left: -21%;
              }
            }
          }
          .links{
            a:first-child{
              right: 36%!important;
              top:1%!important;
            }
            img{
              width: 45%;
              margin-top: 8rem;
            }
            a:last-child{
              top: 35%;
              right:27%!important;
            }
          }
          .carousel-control{
            .glyphicon-chevron-left{
              top:41%;
            }
            .glyphicon-chevron-right{
              top:41%;
            }
          }
        }
      }
      .news{
        .news-panel{
          .pull-right{
            img{
              padding-top: 55%!important;
              padding-left: 25%!important;
            }
          }
        }
      }
      .details{
        .details-panel{
          .view{
            margin: -12rem 0 3rem 0;
            .btn-default{
              margin-top: -9rem;
            }
          }
        }
      }
      .about{
        .about-footer{
          .col-lg-9{
            p{
              font-size:16px;
            }
          }
        }
        .col-lg-3{
          margin-top: -5%;
          .btn-default{
            float: left;
            margin-left: 30%;
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          ul{
            padding: 0!important;
            margin: 0 auto!important;
            li{
              margin-left: 0!important;
            }
          }
        }
        .col-lg-4{
          margin-top: 0;
          text-align: center;
        }
        p{
          margin-left: 14rem;
        }
        .img-responsive{
          float: right;
          padding-right: 25%;
        }
      }
    }
  }

  .articole{
    .container{
      .row{
        text-align: left;
        .col-md-4 img{
          width: 100%;
        }
        .date{
          margin-bottom: -1rem!important;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
        }
      }
      .row{
        .text-center{
          margin-left: 8%;
        }
      }

    }
  }
}
@media only screen and (min-width : 612px) and (max-width : 960px) {
  .homepage{
    .container{
      .slider{
        #myCarousel{
          .carousel-inner{
            background-size: contain!important;
            margin-top: 15%;
          }
          .item {
            //height: 275px;
            .bannerImage {
              left: 18%!important;
              width: 30%!important;
              top:6%;

            }
            .mainImg {
              width: 46%;
            }
            .description{
              top:4%;
              h1{
                margin: 49% 0 0 -20%;
                font-size: 25px;
              }
              p{
                font-size: 14px;
                margin-left: -21%;
              }
            }
          }
          .links{
            a:first-child{
              right: 28%!important;
              top:1%!important;
            }
            img{
              width: 45%;
              margin-top: 8rem;
            }
            a:last-child{
              top: 25%;
              right:12%!important;
            }
          }
          .carousel-control{
            .glyphicon-chevron-left{
              top:41%;
            }
            .glyphicon-chevron-right{
              top:41%;
            }
          }
        }
      }
      .news{
        .news-panel{
          .text-center{
            h2{
              font-size: 48px;
              color: #fff;
              margin-top: 5rem;

            }
          }
          .pull-right{
            margin: 2rem -1rem 0 5rem;
            img{
              padding-top: 0!important;
            }
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              margin-top: 6rem;
            }
          }
          .view{
            .btn-default{
              margin-top: 2rem!important;
            }
          }
        }
      }
      .about{
        .col-lg-7{
          margin-left: 0;
          p{
            font-size: 18px;
            margin-left: 5rem;
            img{
              float: right;
              margin: -3rem 7rem 0 22rem;
            }
          }
        }
        .col-lg-5{
          margin-left: 2rem;
          .btn-default{
            margin-left: 14rem;
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          ul{
            font-size: 14px!important;
            li{
              margin-left: 11%!important;
            }
          }
          p{
            font-size: 12px;
            margin: 1rem 0 0 -14rem;
          }
          .img-responsive{
            float: right!important;
            padding-right: 25%!important;
          }
        }
        .col-lg-5{
          float: left;
          margin: 0!important;
          p{

          }
          .img-responsive{

          }
        }
        .kangur{
          width: 139px;
          height: 290px;
          display: none;
        }
      }
    }
  }
  .page{
    footer{
      .footer{
        .col-lg-7{
          ul{
            margin-left:-11rem;
            li{
              font-size: 12px;
              margin: 0 -7rem 0 7rem;
            }
          }
          p{
            font-size: 12px;
            margin: 1rem 0 0 -14rem;
          }
          .img-responsive{
            margin: -6rem 19rem 0 -3rem;
          }
        }
        .col-lg-4{
          p{
            padding: 15px 0 0 50px!important;
          }
          .img-responsive{
            float: right!important;
            padding-right: 25%!important;
            padding-top: 10px;
          }
        }
        .kangur{
          width: 139px;
          height: 290px;
          display: none!important;
        }
      }
    }

  }
  .articole{
    .container{
      .row{
        text-align: left;
        .col-md-4{
          width: auto;
          padding-left: 4rem;
        }
        .date{
          margin: 0 2rem 0 0;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
        }
      }
      .row{
        .text-center{
          margin-left: 8%;
        }
      }

    }
    .articol{
      img{
        width: 75%;
        height: auto;
        margin: 15rem 0 2rem 5rem;
      }
    }
    footer{
      .footer{
        .col-lg-4{
          margin-top: 20px;
          .img-responsive{
            float: right!important;
            padding-right: 37%!important;

          }
        }
      }
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1366px){
   .homepage{
     .container{
       .slider{
         margin-top: -3rem;
         #myCarousel{
           .carousel-inner{
             background-size: cover!important;
           }
           .item {
             //height: 575px;
             .bannerImage {
               left: 14%!important;
               width: 40%!important;
             }
             .mainImg {
               width: 46%!important;
             }
             .description{
               h1{
                 margin: 4% 0 0 -8%;
               }
               p{
                 font-size: 25px;
                 margin-left: -8%;
               }
             }
           }
           .links{
             a:first-child{
               right: 37%;
               top:25%;
             }
             img{
               width: 45%;
               margin-top: 8rem;
             }
             a:last-child{
               top: 35%;
               left: 58%;
             }
           }
         }
       }
     }
    .news{
      .news-panel{
        .pull-right{
          //margin-right: -22rem;
        }
      }
    }
    .details{
      .details-panel{
        .view{
          margin: -12rem 0 3rem 0;
          .btn-default{
            margin-top: -9rem;
          }
        }
      }
    }
    .about{
      .col-lg-8{
        .btn-default{
          margin-left: 50px!important;
        }
      }
      .col-lg-3{
        float: left;
        margin-top: 50px;
      }
    }
  }
  footer{
    .footer{
      ul{
        margin-left: -11rem;
      }
      .col-lg-5{
        float: left;
        margin: -3rem 0 0 50rem;
      }
      p{
        padding-top: 10px!important;
      }
      .img-responsive{
        /*float: left!important;*/
        margin: 0;
      }
    }
  }

  .page{
    footer{
      .footer{
        .col-lg-4{
          p{
            float: left!important;
          }
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        text-align: left;
        .col-md-4 img{
          width: 100%;
        }
        p.date{
          margin-right: 1rem;
          margin-bottom: 2rem;
        }
        h1{
          margin-left: 1rem;
        }
        h2{
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
        }
      }
      .row{
        .text-center{
          margin-left: 8%;
        }
      }

    }
    footer{
      .footer{
        .col-lg-4{
          margin-top: 0;
          .img-responsive{
            float: right;
            padding-right: 0!important;
          }
        }
      }
    }
  }
}
@media only screen and (min-width : 320px) and (max-width : 533px) {
  .container{
    .contact{
      padding-right: 0;
        .adress{
          margin: 0!important;
          width: 100%!important;
        }
        .phone{
          margin: 0;
          width: 100%;
        }
        .social{
          margin: 0;
          width: 100%;
        }
      }
    }
  footer{
    .footer{
      ul{
        margin-left:-11rem;
        li{
          font-size: 12px;
          margin: 0 -7rem 0 7rem;
        }
      }
      p{
        font-size: 12px;
        margin: 1rem 0 0 -14rem;
      }
      .img-responsive{
        margin: -6rem 19rem 0 -3rem;
      }
      .kangur{
        width: 139px;
        height: 290px;
        display: none;
      }
    }
  }
}
@media only screen and (max-width : 1280px) and (min-width : 720px) {
  .homepage{
    .container{
      #myCarousel{
        .carousel-inner{
          background-size: contain!important;
        }
        .item {
          //height: 575px;
          .bannerImage {
            left: 13%;
            width: 40%;
          }
          .mainImg {
            width: 46%;
          }
          .description{
            h1{
              margin: 30% 0 0 -8%;
              font-size:48px;
            }
            p{
              font-size: 28px;
              margin-left: -8%;
            }
          }
        }
        .links{
          a:first-child{

          }
          img{
            width: 45%;
            margin-top: 8rem;
          }
          a:last-child{
            top: 50%;
            right: 26%;
          }
        }
      }
      .about{
        .col-lg-3{
          margin-top: -5px;
        }
      }
    }
    footer{
      .footer{
        ul{
          margin-left: 20px;
          font-size: 18px!important;
        }
        p{
          margin: 2rem 0 0 -9rem;
        }
        .img-responsive{

        }
        .col-lg-5{
          float: right;
          //margin-right: 17%;
          //margin-left: 40%!important;
          p{
            margin-top: 40px!important;
          }
        }
      }
    }
  }
  .page{
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right: 1px solid #fff;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right: 1px solid #fff;
          }
          .text-right{
            margin-top: 0!important;
          }
        }
      }
    }
    footer{
      .footer{
        .col-lg-8{
          margin: 0 auto;
          padding-left: 60px;
          ul{
            li{
              padding-right: 40px;
            }
          }
        }
        .col-lg-4{
          float: left;
          margin: 0 auto;
          p{
            float: right!important;
            padding-right: 60%;
            margin-top: 7px;
          }
        }
      }
    }
  }
  .articole{
    .container{
      .row{
        text-align: left;
        .col-md-4 img{
          width: 100%;
        }
        p.date{
          margin-right: 2rem;
          margin-bottom: 4rem;
        }
        h1{
          float: left;
          margin-left: 1rem;
        }
        h2{
          float: left;
          margin-left: 1rem;
        }
        h3{
          padding-top: 10rem;
        }
      }
      .row{
        .text-center{
          margin-left: 8%;
        }
      }

    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 85px!important;
            }
          }
        }
      }
    }
  }

}
@media only screen and (min-width : 720px) and (max-width : 1280px) {
  .homepage{
    .container{
      .slider{
        .item {
          img{
            width:100%;
          }
        }
        #myCarousel{
          .carousel-inner{
            background-size: cover!important;
            margin-top: 3%;
          }
          .item {
            //color:red;
            //height: 326px;
            img{
              width:100%;
            }
            .bannerImage {
              left: 22%!important;
              width: 30%!important;

            }
            .mainImg {
              width: 46%;
            }
            .description{
              top:4%;
              h1{
                margin: 49% 0 0 -20%;
                font-size: 30px;
              }
              p{
                font-size: 18px;
                margin-left: -21%;
              }
            }
          }
          .links{
            a:first-child{
              right: 31%!important;
              top:1%!important;
            }
            img{
              width: 45%;
              margin-top: 8rem;
            }
            a:last-child{
              top: 35%;
              right:23%;
            }
          }
          .carousel-control{
            .glyphicon-chevron-left{
              top:41%;
            }
            .glyphicon-chevron-right{
              top:41%;
            }
          }
        }
      }
      .news{
        .news-panel{
          .pull-right{
            //margin-right: -24%!important;
            img{
              //margin-left: -40%!important;
            }
          }
        }
      }
      .details{
        .details-panel{
          .row{
            .text-centrat{
              margin-top: 6rem;
            }
            .text-right{
              img{
                margin-bottom: 3rem;
              }
            }
          }
        }
      }
      .about{
        .col-lg-7{
          p{
            margin-left: 2rem;
            font-size: 18px;
            img{
              margin-top: -1rem!important;
              margin-right: -2rem!important;
            }
          }
        }
        .btn-default{
          /*margin: 3rem 0 -2rem 15rem;*/
        }
      }
    }
    footer{
      .footer{
        ul{

          li{
            font-size: 18px;
            padding: 0 1rem;
            margin: 0 -8rem 0 8rem;
          }
        }
        p{
          font-size: 12px;

        }
        .img-responsive{

        }
        .col-lg-5{
          p{
            margin: 4rem 0 0 5rem;
            width: 80%;
            text-align: center;
          }
          .img-responsive{
            float: right;
          }
        }
      }
    }
  }
  .page{
    .container{
      .details{
        .details-panel{
          .text-left{
            margin: 0;
            border-right:none;
          }
          .text-centrat{
            margin-top: 0!important;
            border-right:none;
          }
          .text-right{
            border-right:none;
          }
        }
      }
    }
    footer{
      .footer{
        ul{
          margin-left: -12rem;
          li{
            font-size: 12px;
            padding: 0 1rem;
            margin: 0 -8rem 0 8rem;
          }
        }
        p{
          font-size: 12px;
          margin: -2rem 0 0 36rem;
        }
        .img-responsive{
          margin: -6rem 17rem 0 0;
        }
        .col-lg-5{
          margin: 0;
          p{
            margin-left: 36%;
            margin-top: 3%;
          }
          img{
            margin: -6rem 32% 0 0;
          }
        }
      }
    }
  }
  .articole{
    footer{
      .footer{
        img-responsive{
          padding-top: 34px!important;
        }
      }
    }
  }
  .comanda{
    .container{
      .details{
        .tabel{
          .form-group{
            .read-more{
              margin-left: 30%!important;
            }
          }
        }
      }
    }
  }

}

/*End Home*/

/*Contact*/

@media only screen and (max-width : 1280px) and (min-width : 800px) {
  .container{
    .contact{
      hr{
        margin: 0 -2rem 0 -2rem;
      }
    }
  }
  footer{
    .footer{
      ul{
        margin-left: -7rem;
      }
      .col-lg-4{
        padding-top: 45px;
        .img-responsive{
          margin-top: -55px;
        }
      }
    }
    .kangur{
      display: none;
    }
  }
}
@media only screen and (max-width : 1024px) and (min-width : 768px) {
  .container{
    .contact{
      hr{
        margin: 0 -2rem 0 -2rem;
      }
      .row{
        .adress{
          margin-right: 50rem;
          h2{
            font-size:18px;
          }
        }
        .phone{
          padding-left: 18%;
        }
        .social{
          margin-top: -11rem;
        }
      }
    }
  }
  footer{
    .footer{
      ul{
        margin-left: 2rem;
      }
      .col-md-4{
        padding-top: 18px;
        float: left;
        .img-responsive{
          padding-left: 15px;
        }
      }
    }
    .kangur{
      display: none;
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .container{
    .contact{
      .row{
        .adress{
          margin-right: 47rem;
          h2{
            margin-bottom: 2rem;
          }
        }
        .phone{
          margin: -12rem 0 0 20rem;
          h2{
            margin-left: -2rem;
          }
          p{
            margin-left: -2rem;
          }
        }
        .social{
          margin-top: -12rem;
          padding-left: 65%;
        }
      }
    }

  }
  footer{
    .footer{
      .col-lg-4{
        margin-left: -20px;
        p{
          margin: 0!important;
          font-size:14px!important;
        }
        .img-responsive{
          margin-top: -60px;
        }
      }
    }
  }
}
@media only screen and (min-width : 414px) and (max-width : 736px) {
  .container{
    .details{
      .form-group{
        textarea{
          width: 100%;
        }
      }
    }
    .contact{
      .row{
        .adress{
          margin-right: -3rem;
          h2{
            margin-bottom: 2rem;
            font-size:22px;
          }
          p{
            font-size:16px;
          }
        }
        .phone{
          margin: 0;
          float: left;
          border: none;
          h2{
            margin-left: -2rem;
            margin-top: 20px;
            margin-bottom: 10px;
          }
          p{
            margin-left: -2rem;
          }
        }
        .social{
          margin-top: -12rem;
          padding-left: 18%;
        }
      }
    }

  }
  footer{
    .footer{
      ul{
        margin-left: -14rem;
      }
      p{
        margin-left: 9rem;
      }
      .img-responsive{
        float: right;
        margin-right: 8rem;
      }
    }
  }
}
@media only screen and (min-width : 375px) and (max-width : 667px) {
  .container{
    .details{
      .text-center{
        h3{
          margin-top: 11rem!important;
        }
      }
      .form-group{
        textarea{
          width: 100%;
        }
      }
    }
    .contact{
      .row{
        .adress{
          margin-right: -3rem;
          h2{
            margin-bottom: 2rem;
            font-size:22px;
          }
          p{
            font-size:16px;
          }
        }
        .phone{
          margin-left: 4rem;
          float: left;
          h2{
            margin-left: -2rem;
          }
          p{
            margin-left: -2rem;
          }
        }
        .social{
          margin-top: 0;
          padding-left: 2rem;
        }
      }
    }

  }
  footer{
    .footer{
      ul{
        margin-left: -14rem;
      }
      p{
        margin-left: 6rem;
      }
      .img-responsive{
        float: right;
        margin-right: 7rem;
      }
    }
  }
}
@media only screen and (min-width : 320px) and (max-width : 568px) {
  .container{
    .details{
      .text-center{
        h3{
          margin-top: 10rem!important;
        }
      }
      .form-group{
        textarea{
          width: 100%;
        }
      }
    }
    .contact{
        .adress{
          margin-left: 5rem;
          border-right: none!important;
          h2{
            margin-bottom: 2rem;
            font-size:22px;
          }
          p{
            font-size:16px;
          }
        }
        .phone{
          margin-left: 15px!important;
        }
        .social{
          margin-top: 0;
          padding-left: 18%;
        }
    }

  }
  footer{
    .footer{
      ul{
        margin-left: -14rem;
      }
      p{
        margin-left: 4rem;
      }
      .img-responsive{
        float: right!important;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 600px) and (max-width : 960px) {
  .container{
    .contact{
      .row{
        .adress{
          margin-left: 10rem;
        }
        .phone{
          margin-left: 8rem;
        }
        .social{
          padding-left: 21%;
        }
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: -1rem 0 0 -12rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-4{
        margin: 1rem 0 0 45rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (max-width : 1024px) and (min-width : 768px) {
  .container{
    .contact{
      hr{
        margin: 0 -2rem 0 -2rem;
      }
      .row{
        .adress{
          margin-right: 50rem;
          h2{
            font-size:18px;
          }
        }
        .phone{
          padding-left: 18%;
        }
        .social{
          margin-top: -11rem;
        }
      }
    }
  }
  footer{
    .footer{
      ul{
        margin-left: 2rem;
      }
      .col-md-5{
        margin: 0 auto;
      }
    }
    .kangur{
      display: none;
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1366px) and (orientation : portrait){
  .navbar{
    width: 100%;
    .navbar-nav{
      margin:0;
    }
    .navbar-header{
      max-width: 30%;
    }
    .navbar-brand{
      img{
        margin-top: 10px;
        max-width:100%;
      }
    }
  }

  .container{
    .contact{
      .row{
        .adress{
          margin: 0;
          padding-left: 2rem;
        }
        .phone{
          margin: 0;
          padding-left: 7rem;
        }
        .social{
          margin: 0;
          padding-left: 2rem;
        }
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: -1rem 0 0 -12rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 1rem 0 0 45rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 720px) and (max-width : 1280px) {
  .container{
    .contact{
      .row{
        .adress{
          margin: 0;
          border-right: none;
        }
        .phone{
          margin: 0;
          border-right: none;
        }
        .social{
          margin: 0;
          padding-left: 2rem;
        }
      }
    }
  }
  footer{
    .footer{
      .col-lg-4{
        margin-top: 15px!important;
        p{
          font-size:14px!important;
          padding-top: 20px;
        }
      }
      .img-responsive{
        float: left;
        padding-top: 45px!important;
      }
    }
  }
}

/*End Contact*/

/*Avantaje*/

@media only screen and (max-width : 1280px) and (min-width : 800px) {
  .container{
    .page-description{
      img{
        margin-right: -2rem!important;
        float: right;
      }
      h1{
        margin-top: 12rem;
      }
      ul{
        width: 50%;
        margin-top: 6rem!important;
        float: left;
      }
    }
  }
  footer{
    .footer{
      .col-lg-4{
        padding-right: 20%;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 3rem;
      }
    }
  }
}
@media only screen and (min-width : 800px) and (max-width : 1280px) {
  .container{
    .page-description{
      img{
        float: none!important;
      }
      ul{
        width: 100%!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 10px;
        margin-left: -11rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: -3rem 0 0 53rem;
      }
      .img-responsive{
        margin-left: 3rem!important;
        float: none!important;
      }
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .container{
    .page-description{
      h1{
        margin-top: 12rem;
      }
      ul{
        width: 100%!important;
        float: left;
        margin-top: 2rem;
        text-align: left !important;      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 10px;
        margin-left: -11rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 1rem 0 0 53rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (max-width : 1024px) and (min-width : 768px) {
  .container{
    .page-description{
      h1{
        margin-top: 12rem;
      }
      ul{
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 10px;
        margin-left: -11rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: -3rem 0 0 57rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 3rem;
      }
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .container{
    .page-description{
      h1{
        margin-top: 12rem;
      }
      ul{
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 10px;
        margin-left: -11rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 1rem 0 0 52rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 414px) and (max-width : 736px) {
  .container{
    .page-description{
      img{
        float: right;
        width: 357px!important;
        height: 180px!important;
        margin-bottom: 3rem;
      }
      ul{
        width: 100%;
        text-align: left;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: 0;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0;
        }
      }
      .col-lg-5{
        margin: 6rem 0 0 17rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 375px) and (max-width : 667px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 15rem!important;
      }
      img{
        float: right;
        width: 270px!important;
        height: 180px!important;
        margin-bottom: 3rem;
      }
      ul{
        width: 100%;
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: -1rem 0 0 -11rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 6rem 0 0 17rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 320px) and (max-width : 568px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 15rem!important;
      }
      img{
        float: right;
        width: 270px!important;
        height: 180px!important;
        margin-bottom: 3rem;
      }
      ul{
        width: 100%;
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: 0;
        li{
          font-size: 12px;
          margin: 0;
        }
      }
      .col-lg-5{
        margin: 6rem 0 0 17rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 360px) and (max-width : 640px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 15rem!important;
      }
      img{
        float: right;
        width: 270px!important;
        height: 180px!important;
        margin-bottom: 3rem;
      }
      ul{
        width: 100%;
        text-align: left!important;
        float: left;
      }
    }
  }
  footer{
    .footer{
      .bar{
        padding-left: 5px;
      }
      ul{
        font-size: 7px;
        margin: 0;
        width:100%;
        li{
          font-size: 12px;
          padding: 0 5px;
          margin: 0;
          a{
            margin-left: 3px;
          }
        }
      }
      .col-lg-5{
        margin: 0;
        p{
          margin: 0;
          width: 60%;
          text-align: right;
        }
      }
      .img-responsive{
        float: right;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (max-width : 960px) and (min-width : 600px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 4rem;
      }
      img{
        float: right;
        width: 270px!important;
        height: 180px!important;
        margin-bottom: 3rem;
      }
      ul{
        width: 100%;
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      margin: 0!important;
      ul{
        font-size: 12px!important;
        margin: 0;
        li{
          font-size: 12px;
          margin: 0;
        }
      }
      .col-lg-5{
        margin: 0;
        p{
          margin: 0;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (min-width : 600px) and (max-width : 960px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 4rem;
      }
      img{
        width: 532px!important;
        height: 277px!important;
      }
      ul{
        width: 100%;
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: -1rem 0 0 -12rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 1rem 0 0 45rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 1rem;
      }
    }
  }
}
@media only screen and (max-width : 1366px) and (min-width : 768px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 4rem;
      }
      img{
        float: right;
        width: 465px;
        height: 220px;
        margin-right: 8rem;
      }
      ul{
        width: 100%;
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: -1rem 0 0 -12rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 1rem 0 0 45rem;
        p{
          margin: -3rem 0 0 -10rem;
        }
      }
      .img-responsive{
        float: none!important;
        margin-left: 11rem!important;
      }
      .kangur{
        display: none;
      }
    }
  }
}
@media only screen and (min-width : 768px) and (max-width : 1366px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 14rem;
      }
      img{
        float: right;
        width: 465px;
        height: 220px;
        margin-right: 8rem;
      }
      ul{
        width: 100%;
        text-align: left!important;
        float: left;
      }
    }
  }
  footer{
    .footer{
      margin-top: 4rem;
      ul{
        font-size: 12px;
        margin: -1rem 0 0 -9rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        margin: 1rem 0 0 45rem;
        p{
          //margin: -3rem 0 0 -3rem;
          margin: -3rem 0 0 7rem;
        }
        .img-responsive{
          float: right!important;
          margin-left: 11rem!important;
        }
      }
      .img-responsive{
        float: left;
        margin-left: 8rem;
      }
      .kangur{
        display: none;
      }
    }
  }
}
@media only screen and (max-width : 1280px) and (min-width : 720px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 4rem;
      }
      img{
        float: right;
        width: 465px;
        height: 220px;
        margin-right: 8rem;
      }
      ul{
        width: 47%;
        margin-top: -30rem;
        text-align: left!important;
      }
    }
  }
  .contact-body .container .details {
    margin-top:50px;
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: -1rem 0 0 -12rem;
        li{
          font-size: 12px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        padding-top:30px;
      }
      .img-responsive{
        float: left;
        margin-left: 3rem;
        margin-top: -15%;
      }
      .kangur{
        display: none;
      }
    }
  }
}
@media only screen and (min-width : 720px) and (max-width : 1280px) {
  .container{
    .page-description{
      h1.text-center{
        margin-top: 13rem;
      }
      img{
        width: 532px!important;
        height: 277px!important;
      }
      ul{
        width: 100%;
        float: left;
        margin-top: 2rem;
        text-align: left!important;
      }
    }
  }
  footer{
    .footer{
      ul{
        font-size: 7px;
        margin: 1rem 0 0 -11rem;
        li{
          font-size: 14px;
          padding: 0 1rem;
          margin: 0 -8rem 0 8rem;
        }
      }
      .col-lg-5{
        //margin: 0;
        p{

        }
      }
      .img-responsive{
        float: right;
        margin-left: 1rem;
      }
    }
  }
}

/*End Avantaje*/