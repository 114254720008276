.articole{
  .container{
    background: #bf171f!important;
    min-height:800px;
    .row{
        max-width: 1170px;
        margin:0 auto;
        position: relative;
        .date{
          position: absolute;
          right: 0;
          bottom: 20rem;
          font-size: 14px;
          line-height: 16px;
          color: #fff;
          background-color: #00a651;
          padding: 10px;
          text-align: center;
        }
      hr{
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #ea4047;
      }
      a{
        color: #fbb306;
        text-decoration: none;
        font-size: 12px;
        line-height: 24px;
        margin-left: 1rem;
      }
      h1{
          font-size: 18px;
          color: #fff;
        }
      h2{
          font-size: 14px;
          line-height:24px;
          color: #fff;
          font-weight: 300;
        }
      h3{
          text-align: center;
          padding-top: 30px;
          margin-bottom: 25px;
          font-size: 48px;
          color: #fff!important;
        }
      .read-more {
        padding: 10px 35px;
        background: #ffcb08;
        font-size: 18px;
        color: #fff;
        border: none;
      }
    }
    .row{
      .text-centered{
        margin-left: 10rem;
        //padding-left: 33%;
        ul{
          background: #bf171f;
          li.disabled span{
            color: #ffcb08;
            background: transparent;
            border: none;
            font-size: 16px;
          }
          li.active span{
            color: #ffcb08;
            background: transparent;
            border: 1px solid #ffcb08;
            font-size: 16px;
          }
          li a{
            color: #ffcb08;
            background: transparent;
            border: none;
            font-size: 16px;
          }
        }
      }
    }
  }
  .articol{
    .nextArticoles{
      margin-top: 2em;
      padding:0;
      .col-md-6{
        padding:0;
        margin:0;
      }
    }
    .pull-left{
      margin-top: 1rem;
      font-size:12px;
      line-height: 24px;
      color: #fbb306;
    }
    .pull-right{
      margin-top: 1rem;
      font-size:12px;
      line-height: 24px;
      color: #fbb306;
    }
    img{
      margin: 4rem 3em 0 0;
      float: left;
    }
    h1{
      font-size: 30px!important;
      margin: 4rem 15rem 3rem 0;
    }
    h2{
      margin-bottom: 4rem;
    }
    hr{
      margin-bottom: -2rem!important;
    }

  }
}

