body, html{
  max-width:100%;
  max-height:100%;
  width:100%;
  height:100%;
}
header {
  background-color: #d71921;

  //max-width:1170px;
  //margin:0 auto;
  //background-color: $red;
  //height:110px;
}
.navbar, .navbar:hover {
  max-width: 1170px;
  margin: 0 auto;
  height: 100px;
  background-color: #d71921;
  background-image: none;
  padding: 0;
  .container-fluid, .navbar-collapse {
    height: 100% !important;
  }

  .open, .active{
    a, a:hover, a:focus{
      color: #fff;
    }
  }
  .navbar-nav {
    height: 100%;
    //margin-top: -5rem;

    .open, .active{
      a, a:hover, a:focus{
        color: #fff;
      }
    }
    li {
      height: 100%;
      .dropdown-menu, .dropdown-menu:hover, .dropdown-menu:focus, a, a:hover, a:focus{
        background-color: #d71921;
        background-image: none;
      }
      .dropdown-menu{
        li{
          a, a:hover, a:focus{
            width: 100%;
            padding: 10px;
            text-align: center;
          }
        }
      }

      a, a:hover, a:focus {
        height: 100%;
        vertical-align: middle;
        display: inline-block;
        font-weight: 600;
        color: #fff;
        padding: 40px 20px 0 20px;
        text-transform: uppercase;
      }
    }
  }
}

.container {
  background: linear-gradient(to left, #024824, #00954b 25%, #00954b 75%, #024824 100%);
  width: 100%;
  padding: 0;
  position: relative;
  /*margin-bottom: 140px;*/
  min-height: 700px;
  .row {
    margin: 0;
  }
  .news {
    background-color: #d71921;
    padding-bottom: 10rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    .news-panel{
      height:100%;
      max-width: 1170px;
      margin:0 auto;
      .text-center {
        h2 {
          font-size: 48px;
          color: #fff;
          margin-top: 5rem;
        }
        p {
          font-size: 24px;
          line-height: 22px;
          color: #fff;
          margin-bottom: 5rem;
        }
      }
      .pull-left {
        ul{
          font-size:14px;
          color: #fff;
          padding: 1rem 0 1rem 4rem;
          margin-right: 3rem;
          background: linear-gradient(to left, #d41820, #c8141b 25%, #940007 75%, #940007 100%);
          li{
            list-style-type: square;
          }
          hr{
            margin-top: 20px;
            margin-bottom: 20px;
            border: 0;
            border-top: 3px solid #d71921;
            margin-left: -4rem;
          }

        }
      }
      .pull-right{
        float: right;
        margin-top: -40rem;
        margin-right: 3rem;
      }
    }
  }
  .details{
    background: linear-gradient(to left, #024824, #00954b 25%, #00954b 75%, #024824 100%);
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 3rem;
    .details-panel{
      height:100%;
      max-width: 1170px;
      margin:0 auto;
      h3{
        text-align: center;
        color: #fff;
      }
      .row{
        margin-top: 9rem;
        margin-bottom: 6rem;
        h2{
          font-size:18px;
          color: #fff;
          text-align: center;
        }
        p{
          font-size:18px;
          color: #fff;
        }
        .text-left{
          border-right:1px solid #fff;
        }
        .text-centrat{
          border-right:1px solid #fff;
        }
      }
      .view{
        margin: -3rem 0 -5rem 0;
        .btn-default{
          color: #00a651;
          font-size:22px;
          background-color: #fff;
          padding: 5px 5rem 5px 5rem;
          border-radius: 0;
        }
      }
    }
  }
  .about {
    background-color: #fbb306;
    padding: 4rem 0 6rem 0;
    .about-footer{
      max-width:1170px;
      margin:0 auto;
    }
    p {
      font-size: 25px;
      color: #d71921;
      span{
        font-size:.8em;
      }
      img{
        margin-left: 0;
      }
    }
    .btn-default {
      font-size: 22px;
      color: #d71921;
      margin-left: -3rem;
      border-radius: 0;
      padding: 1rem 6rem 1rem 6rem;
      -webkit-box-shadow: -9px 10px 5px 0px #ba8504;
      -moz-box-shadow: -9px 10px 5px 0px #ba8504;
      box-shadow: -9px 10px 5px 0px #ba8504;
    }
  }

}
footer{
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 2em 0;
  position: relative;
  .footer {
    max-width: 1170px;
    background-color: #fff;
    margin: 0 auto;
    height:auto;
    ul{
      list-style-type: none;
      margin-left: -4rem;
      margin-top: 1em;
      li{
        color: #d71921;
        float: left;
        font-size: 14px;
        padding:0;
        a, a:focus, a:hover{
          color: #d71921;
        }
      }
    }
    p{
      margin-left: 2rem;
      font-size: 14px;
      line-height: 16px;
      color: #d71921;
    }
    .img-responsive{
      float: right;
      margin: -7rem 20rem 0 0;
    }
    .bar{
      border-left:1px solid #d71921;
    }

  }
  .kangur{
    background: url("../../../img/kangur.png") no-repeat;
    width: 280px;
    height: 380px;
    bottom: 0;
    right: 0;
    position: absolute;
    border: 0;
    z-index: 2000;
  }
}
.navbar-default .navbar-toggle{
  /*padding:0;*/
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
  background-color: transparent;
}
.navbar-default .navbar-toggle .icon-bar{
  color: $lightblack;
}
.container .navbar-toggle {
  float: left;
}
.navbar .navbar-nav>.active>a, .navbar .navbar-nav>.active>a:hover, .navbar .navbar-nav>.active>a:focus{
  background-color: #b3151d!important;
  border-bottom: 3px solid #ffcb08;
}
.navbar-nav{
  //margin-left: 17rem !important;
}

.homepage{
  .container{
    height:initial;
    margin-bottom: 0;
    background: transparent!important;
  }
}
.contact-body {
  .container{
    margin-bottom: 0;
  }
}
.page{
  height:100%;
  .page-description{
    max-width: 1170px;
    margin: 0 auto;
    color: #fff;
    h1{
      font-size: 48px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 60px;
      padding-top:0;
    }
    p{
      font-size:18px;
      text-align: left;
      span{
        color: #ffcb08;
      }
    }
    h2{
      text-align: center;
      font-size:30px;
      margin-bottom: 8rem;
    }
    div{
      text-align: center;
      font-size: 18px;
    }
    h3{
      font-size: 24px;
      font-weight: 200;
      margin-top: 5px;
      padding-top: 5px;
      text-align: center;
    }
    img{
        margin-top: 80px;
    }
    ul{
      width: 50%;
      margin-top:50px;
      li{
        list-style: square;
        font-size: 18px;
        font-weight: 200;
        text-align: left;
        margin-bottom: 20px;
      }
    }
    table{
      border-collapse: collapse;
      border-spacing: 0;
      border-style: hidden;
      tbody{
        tr, td{
          font-size: 18px;
          padding-right: 5rem;
          border-left:hidden;
          text-align: left;
        }
      }
    }

  }
  .details{
    background: transparent;
    width: 100%;
    margin-bottom: 1rem;
    padding-top: 3rem;
    .details-panel{
      height:100%;
      max-width: 1170px;
      margin:0 auto;
      .row{
        margin-top: 9rem;
        margin-bottom: 6rem;
        h2{
          font-size:18px;
          color: #fff;
          text-align: center;
        }
        p{
          font-size:18px;
          color: #fff;
        }
        .text-left{
          border-right:1px solid #fff;
        }
        .text-centrat{
          border-right:1px solid #fff;
        }
      }
      .text-center{
        margin: -6rem 0 -2rem 0;
        .btn-default{
          color: #00a651;
          font-size:22px;
          background-color: #fff;
          padding: 5px 5rem 5px 5rem;
          border-radius: 0;
          margin: 4rem 0 -5rem 0;
        }
      }
    }
  }
}

.confidentialitate {
  height: 100%;
  .page-description {
    h1 {
      font-size: 48px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-bottom: 0;
      margin-top: 60px;
      padding-top: 0;
    }
    p {
      font-size: 14px!important;
    }
    ul{
      width:100%;
      li{
        font-size: 14px!important;
      }
    }
  }
}




